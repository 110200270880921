import { gsap } from "gsap"
import mitt from 'mitt'

export default class Player {
    constructor(_container) {
        this.container = _container
        //
        this.wrapper = _container.querySelector('.opening-container')
        // this.wrapper = _container.querySelector('.full-container')
        // this.cover = this.container.querySelector('.cover')
        this.preview = this.container.querySelector('.preview')
        this.toggleCursor = this.container.querySelector('.toggle-play-btn')

        this.video = this.container.querySelector('.full')
        this.events = mitt()
        this.setTl()
        this.addEvents()
    }
    
    addEvents() {
        if (!this.video || !this.toggleCursor) return false
        this.events = mitt()
        this.toggleCursor.addEventListener("click", (e) => this.togglePlay(e), false)
        //
        this.video.addEventListener("click", (e) => this.togglePlay(e), false)
        this.video.addEventListener("mouseenter", () => this.onMouseEnter())
        this.video.addEventListener("mousemove", (e) => this.onMouseMove(e))
        this.video.addEventListener("mouseleave", () => this.onMouseLeave())
        this.video.addEventListener("play", () => this.play(), false)
        this.video.addEventListener("ended", () => this.onEnded(), false)
        window.addEventListener("keydown", (e) => {
            if (e.key === "Escape") return this.pause() 
            else if (e.key === "Enter") return this.play()
            else if (e.key === " ") return this.togglePlay(e)
            else return false
        })
    }

    setTl() {
        this.tl = new gsap.timeline({
            paused: true,
            onReverseComplete: () => this.onReverseComplete()
        })
        if (this.preview) {
            this.tl.to(this.preview, {
                ease: 'steps(4)',
                duration: 1,
                opacity: 0,
            }, 0)
        }
        if (this.video) {
            this.tl.to(this.video, {
                ease: 'steps(4)',
                duration: 1,
                opacity: 1,
                volume: 1
            }, 0)
        }
        if (this.wrapper) {
            this.tl.to(this.wrapper, {
                ease: 'steps(4)',
                duration: .5,
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
            }, 0)
        }
    }

    onReverseComplete() {
        if (!this.video) return false
        this.video.muted = true
        this.video.pause()
    }

    togglePlay(e) {
        e.preventDefault()
        if (this.video.paused) return this.play()
        else return this.pause()
    }

    pause() {   
        this.events.emit('pause')
        this.container.classList.remove("is-playing") 
        if (this.video) this.video.pause()
        if (this.preview) this.preview.play()
        return this.tl.reverse()
    }

    play() {
        this.events.emit('play')
        this.container.classList.add("is-playing")
        if (this.preview) this.preview.pause()
        if (this.video) {
            this.video.play()
            this.video.muted = false
        }
        return this.tl.duration(.35).play()
    }

    onEnded() {
        this.events.emit('end')
        return this.pause()
    }

    onMouseEnter() {
        window.CURSOR.show(.3)
        gsap.to(this.preview, { scaleX: 1.1, scaleY: 1.1, filter: 'brightness(100%) saturate(100%) grayscale(0%)', duration: 0.2, ease: 'steps(3)' })
    }

    onMouseLeave() {
        window.CURSOR.hide(.2)
        gsap.to(this.preview, { scaleX: 1.0, scaleY: 1, filter: 'brightness(75%) saturate(150%) grayscale(100%)', duration: 0.2, ease: 'steps(3)' })
    }

    onMouseMove(e) {
        const mouseY = e.clientY
        if (mouseY >= HEIGHT * 0.9) return window.CURSOR.hide(.2)
        else return window.CURSOR.show(.2)
    }

}